import * as React from "react";
import { 
    CFooter
} from "@coreui/react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer=()=>{
    return(
        <>
        <CFooter style={{ backgroundColor: "#800000", textAlign: "left" }}>
            <div>
                <a target="_blank" href="https://africaterer.com/policies/Terms.pdf" rel="noreferrer">Terms and Conditions</a>
                <br/>
                <a target="_blank" href="https://africaterer.com/policies/PrivacyPolicy.pdf" rel="noreferrer">Privacy Policy</a>
            </div>
            <br/>
            <div className="linkText">
                <a href="mailto:support@africaterer.com"><EmailOutlinedIcon /></a> &nbsp;
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/africaterer"><InstagramIcon /></a>
            </div>
        </CFooter>
        <div className="text-center p-3">
            <span>&copy; {new Date().getFullYear()}</span>
        </div>
        </>
    )
}

export default Footer;