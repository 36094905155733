import * as React from "react";
import SharedCheckoutPage from "../components/Checkout";
import { Box, Divider, Typography } from "@mui/material";

const ShopCheckout=()=>{
    const [shoppingCart, setShoppingCart] = React.useState([]);
    const [orderTotal, setOrderTotal] = React.useState(0);
    const [currency, setCurrency] = React.useState("$");
    
    React.useEffect(()=>{
        let cart = JSON.parse(localStorage.getItem("shopCart"))||[];
        if(cart.length<=0)window.location.href = "/shop";
        let curr = "$";
        if(cart.length>0){
            let total = cart.reduce((a, v)=>a + (parseFloat(v.total.substring(1))), 0);
            curr = cart[0].total.substring(0,1);
            setCurrency(curr);
            setOrderTotal(total.toFixed(2));
            setShoppingCart(cart);
        }
    }, []);

    const orderOption = {
        orderType: "delivery",
        fulfillDate: new Date(),
    }

    if(shoppingCart.length<=0){
        return (<div className="App-body">Loading...</div>)
    }

    return (
        <div style={{height: "90vh"}}>
            <Box sx={{
                backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                m: 2,
                gap: 4,
                display: "grid",
                height: "100%",
                gridTemplateColumns: { md: "2fr 1fr" },
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <div>
                    <SharedCheckoutPage currency={currency} cart={shoppingCart} orderOption={orderOption} orderTotal={orderTotal} serviceType="shop" />
                </div>
                <div>
                    <Box sx={{ width: '100%', boxShadow:2, m:2, p:2 }}>
                        <Typography variant="subtitle">Cart</Typography>
                        <Divider/>
                        <div>
                            <table className="table table-bordered" style={{ fontSize: 14}}>
                                <tbody>
                                    {shoppingCart.map((o, i) => (
                                    <tr key={i}>
                                        <td>{o.quantity}{" "}{o?.sizes?.size}{" "}{o.name}</td>
                                        <td>{o.total}</td>
                                    </tr>))}
                                    <tr>
                                        <td colSpan={2} style={{fontWeight: "bold", textAlign: "right"}}>Total: {currency}{orderTotal}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Box>
                </div>
            </Box>
        </div>
    );
}

export default ShopCheckout;