import * as React from "react";
import { 
    Box, FormControl, InputLabel, Typography, Button,
    Select, MenuItem, Grid, Pagination, TextField, ButtonBase,
} from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";

let pageSize = 15;
const CatererList=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    let { caterers, laFilter } = props;
    if(location.state){
        caterers = location.state.caterers;
        laFilter = location.state.laFilter;
    }
    const [sort, setSort] = React.useState("");
    const [pagination, setPagination] = React.useState(10);
    const [paginatedCaterers, setPaginatedCaterers] = React.useState([]);
    let nameFilter = React.useRef("");

    const handleSortChange=(e)=>{
        let sortedCaterers = caterers;
        setSort(e.target.value);
        switch (e.target.value) {
            case "ascending":
                sortedCaterers = caterers.sort((a, b)=>a.businessName.localeCompare(b.businessName));
                setStartEndIndex(1, sortedCaterers);
                break;
            case "descending":
                sortedCaterers = caterers.sort((b, a)=>a.businessName.localeCompare(b.businessName));
                setStartEndIndex(1, sortedCaterers);
                break;
            default:
                setStartEndIndex(1, sortedCaterers);
                break;
        }
    }

    const handlePageChange=(e, pageNo)=>{
        setStartEndIndex(pageNo, caterers);
    }

    const handleNameFilterChange=(e)=>{
        nameFilter.current = e.target.value.toLowerCase();
        setStartEndIndex(1, caterers);
    }

    const setStartEndIndex=(pageNo, cats)=>{
        let filter = nameFilter.current;
        let ncaterers = cats.filter(a=>a.businessName.toLowerCase().includes(filter));
        let endIndex = ncaterers.length;
        let startIndex = (pageNo - 1) * pageSize;
        let nIndex = pageSize*pageNo;
        if(nIndex<endIndex)endIndex = nIndex;
        let x = ncaterers.slice(startIndex, endIndex);
        setPaginatedCaterers(x);
        if(filter!==""){
            setPagination(Math.ceil(x.length/pageSize));
        }
        else{
            setPagination(Math.ceil(cats.length/pageSize));
        }
    }

    const orderFromCaterer=(id)=>{
        navigate(`/vendor${id}`);
    }

    const handleGoBack=()=>{
        if(nameFilter.current){
            nameFilter.current = "";
            setStartEndIndex(1, caterers);
        }
        else{
            navigate(-1);
        }
    }

    React.useState(()=>{
        setStartEndIndex(1, caterers);
    }, []);

    if(paginatedCaterers.length<=0){
        let notFnd = "No caterers found";
        if(laFilter){
            notFnd = `No caterers found matching the search criteria - "${laFilter}"`;
        }
        return (
        <div className="App-body" style={{  backgroundColor: "#fff" }}>
            <p style={{ fontSize: 40, color: "#300044"}}>{notFnd}</p>
            {laFilter&&<Button variant="contained" style={{backgroundColor: "black"}} onClick={handleGoBack}>Go Back</Button>}
        </div>
        )
    }
    return (
    <div className="App-body">
        <div style={{ padding: 15, overflowY: "scroll", height: "100%", width: "100%" }}>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <TextField label="Filter By Caterer Name" size="small" onChange={handleNameFilterChange}/>
                    </FormControl>
                    {/* <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                        <TextField label="Filter by Pickup/Delivery Date" size="small"/>
                    </FormControl> */}
                </div>
                <div>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Sort By:</InputLabel>
                        <Select value={sort} label={"Sort By"} onChange={handleSortChange}>
                            <MenuItem value={"ascending"}>Name: A-Z</MenuItem>
                            <MenuItem value={"descending"}>Name: Z-A</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <Box
            sx={{
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                m: 2,
                gap: 4,
                display: 'grid',
                gridTemplateColumns: { md: 'repeat(3, 1fr)' },
            }}>
                {paginatedCaterers.map((a, i)=>(
                <Grid key={i} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ boxShadow: 2, p:1 }}>
                    <Grid item>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                            <img alt="logo" src={a.imageUrl||require("../images/noimage.jfif")} width='100%' display="block" margin="auto" />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1" component="div">{a.businessName}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <span style={{color: "orange"}}>Cuisine</span>: {a.settings.filter(a=>a.settingName==="general")[0]?.settingValue.filter(g=>g.name==="cuisine")[0]?.value}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color={a.accountStatus==="Read Only"?"error":"success"} size="small" onClick={()=>orderFromCaterer(a.businessLink)}>{a.accountStatus==="Read Only"?"VIEW MENU":"ORDER FROM ME"}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                ))}
            </Box>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination count={pagination} variant="outlined" shape="rounded" onChange={handlePageChange} />
        </div>
    </div>
  );
}

export default CatererList;