import { Box, Divider, Typography, FormControl, Rating,
    FormGroup, TextField, Button
} from "@mui/material";
import * as React from "react";
import { useParams } from 'react-router-dom';
import { GetOrder } from "../functions/GetOrder";
import { AddReview } from "../functions/AddReview";

const Order=()=> {
    let { id } = useParams();
    const [orderDetails, setOrderDetails] = React.useState(null);
    const [vendorOrders, setVendorOrders] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [review, setReview] = React.useState(null);
    const [newReview, setNewReview] = React.useState(null);

    const addReview=async()=>{
        if(!newReview){
            return;
        }if(!newReview.rating || !newReview.review){
            return;
        }
        if(!newReview.reviewer){
            newReview.reviewer = "anonymous";
        }
        newReview._id = review._id;
        await AddReview(newReview);
    }

    React.useEffect(()=>{
        const getOrderDetails=async()=>{
            localStorage.removeItem("shopCart");
            setIsLoading(true);
            const data = await GetOrder(id);
            if(!data||(data.vendorOrder&&data.vendorOrder.length<=0)){
                setIsLoading(false);
                return;
            }
            setOrderDetails(data.vendorOrder[0].order||{});
            setReview(data.review||{});
            let vendorOrderArr = [];
            data.vendorOrder.forEach(async o=>{
                const vendorOrder = {};
                vendorOrder.vendor = o.vendor;
                vendorOrder.details = o.orderDetails;
                vendorOrderArr.push(vendorOrder);
            });
            setVendorOrders(vendorOrderArr);
            setIsLoading(false);
        }

        getOrderDetails();
    }, [id]);

    if(isLoading)return(<div className="App-body">Loading...</div>);
    if(!orderDetails || !orderDetails._id)return(<div className="App-body">Invalid Order</div>);
    return (
        <div className="App-body">
            <div style={{ width: "100%", height: "100%", overflowY: "scroll", margin:10, padding: 0 }}>
                <div style={{ backgroundColor: "#333", color: "#eee", }}>
                    <Typography>Order #{orderDetails._id} placed on {new Date(orderDetails.orderDate).toDateString()}</Typography>
                </div>
                <div style={{ padding: 10}}>
                    <Typography style={{textTransform: "capitalize"}}><span style={{fontWeight: "bold"}}>{orderDetails.order}Status: </span>{orderDetails.status}</Typography>
                    <Typography style={{textTransform: "capitalize"}}><span style={{fontWeight: "bold"}}>Payment:</span> {orderDetails.paymentType}</Typography>
                    <Typography style={{textTransform: "capitalize"}}><span style={{fontWeight: "bold"}}>Order Total:</span> {orderDetails.currency}{orderDetails.total}</Typography>
                    <Typography style={{textTransform: "capitalize"}}><span style={{fontWeight: "bold"}}>Order Type:</span> {orderDetails.orderType}</Typography>
                    <Typography style={{textTransform: "capitalize"}}><span style={{fontWeight: "bold"}}>{orderDetails.orderType} Date:</span> {new Date(orderDetails.orderFulfillDate).toLocaleString([], {dateStyle: "long", timeStyle: 'short'})}</Typography>
                </div>
                <Divider /><Divider color="error" />
                <Box sx={{
                    m: 2,
                    gap: 4,
                    display: "grid",
                    gridTemplateColumns: { md: "1fr 1fr 1fr" },
                }}>
                    {vendorOrders&&vendorOrders.length>0&&vendorOrders.map((vendorOrder, i)=>(
                        <div key={i} style={{borderStyle: "outset"}}>
                            <Typography variant="h5">{vendorOrder.vendor?.businessName}</Typography>
                            <Typography color="text.secondary">{vendorOrder.vendor?.phone}</Typography>
                            {(orderDetails.orderType==="pickup"&&orderDetails.status!=="completed")&&
                            <Typography color="text.secondary">
                                {vendorOrder.vendor?.businessAddress.street}{" "}
                                {vendorOrder.vendor?.businessAddress.apt} {" "}
                                {vendorOrder.vendor?.businessAddress.city},{" "}
                                {vendorOrder.vendor?.businessAddress.state},{" "}
                                {vendorOrder.vendor?.businessAddress.zip}
                            </Typography>}
                            <Divider /><br />
                            {vendorOrder.details.map((o, i)=>(
                                <div key={i}>
                                    <Box sx={{gap: 1, display: "grid", gridTemplateColumns: { xs: "2fr 1fr" }}}>
                                        <div>
                                            <Typography>{o.quantity}{o.serving&&"("+o.serving+")"}{"  "}{o.name||o.food} </Typography>
                                            {o.details&&<Typography style={{fontStyle: "italic", fontSize: 12}}>&nbsp;&nbsp;{o.details}</Typography>}
                                            {o.subs&&o.subs.length>0&& <Typography style={{fontStyle: "italic", fontSize: 12}}>&nbsp;&nbsp;{"w/ " + o.subs.map(s=>s.item).join()}</Typography>}
                                        </div>
                                        <Typography>{orderDetails.currency}{o.price}</Typography>
                                    </Box>
                                    <br />
                                </div>
                            ))}
                        </div>
                    ))}
                </Box>
                <Divider /><Divider color="success" />
                {review!==null&&review._id&&!review.rating&&<Box sx={{p: 2}}>
                    <Typography variant="h5" color="secondary">RATE YOUR EXPERIENCE</Typography>
                    <FormGroup>
                        <FormControl style={{alignItems: "center", }}>
                            <Rating value={review.rating} size="large" onChange={(event, newValue) => {setNewReview((prev) => {return {...prev, "rating": newValue }})}}/>
                            <TextField fullWidth defaultValue={review.reviewer} margin="dense" label="Reviewer" onChange={(e)=>setNewReview((prev) => {return {...prev, "reviewer": e.target.value }})} />
                            <TextField fullWidth defaultValue={review.review} margin="dense" multiline rows={4} label="Review" onChange={(e)=>setNewReview((prev) => {return {...prev, "review": e.target.value }})} />
                            <Button variant="contained" color="success" onClick={addReview}>Add Review</Button>
                        </FormControl>
                    </FormGroup>
                </Box>}
            </div>
        </div>
    );
}

export default Order;