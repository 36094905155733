const ValidateCustomerInfo=async(customerName, customerPhone, customerEmail)=>{
    if(!customerName){
        return "Name is required";
    }
    if(!customerPhone){
        return "Phone is required";
    }
    if(!customerEmail){
        return "Email is required";
    }
    const emailregex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
    if (!emailregex.test(customerEmail)){
        return "Invalid Email Address";
    }
    return null;
}

export default ValidateCustomerInfo;