import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Typography, Paper, InputBase, IconButton, Box, Button, CardHeader, Avatar,
  Select, MenuItem, FormControl, Chip, Stack, Grid, CardActionArea, Card, CardContent, CardMedia,
} from "@mui/material";
import { getAllCaterers } from "../functions/GetCaterers";
import { useNavigate } from "react-router-dom";
import { GetConfig } from "../functions/GetConfig";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { GetPopups } from "../functions/GetPopups";
import SendIcon from "@mui/icons-material/Send";
// import { teal, brown, deepPurple, amber } from "@mui/material/colors";

const Home=()=> {
  const navigate = useNavigate();
  const [searchParam, setSearchParam] = React.useState("");
  const [caterers, setCaterers] = React.useState([]);
  const [country, setCountry] = React.useState(JSON.parse(localStorage.getItem("sctCountry"))||{name: "USA", code: "US", currencySign:"$", currencyCode: "USD"});
  const [operatingCountries, setOperatingCountries] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [popups, setPopups] = React.useState([]);

  const handleCountryChange=async(e)=>{
    let cu = operatingCountries.filter(a=>a.name === e.target.value);
    if(cu.length>0){
      localStorage.setItem("sctCountry", JSON.stringify(cu[0]));
      setCountry(cu[0]);
    }
  }

  const getCurrency=(popup)=>{
    const country = operatingCountries.filter(c=>c.name===popup?.vendor?.businessAddress?.country);
    return country[0]?.currencySign||"$";
  }

  const filterCaterers=()=>{
    getFiltererdCaterers(searchParam);
  }

  const getFiltererdCaterers=(search)=>{
    let searchCrit = search.toLowerCase();
    let filtered = caterers.filter(c=>searchCrit.includes(c.businessName.toLowerCase()) || c.businessName.toLowerCase().includes(searchCrit)
    || searchCrit.includes(c.businessAddress.city.toLowerCase()) || c.businessAddress.city.toLowerCase().includes(searchCrit)
    || searchCrit.includes(c.businessAddress.state.toLowerCase()) || c.businessAddress.state.toLowerCase().includes(searchCrit)
    || searchCrit.includes(c.businessAddress.street.toLowerCase()) || c.businessAddress.street.toLowerCase().includes(searchCrit));

    navigate("/caterers", {state:{caterers: filtered, laFilter: searchParam}});
  }

  const getDaysRemaining=(acceptUntil)=>{
    const diff = new Date(acceptUntil) - new Date();
    const daysRemaining = Math.floor(diff / (1000 * 60 * 60 * 24));
    return `${daysRemaining} day${daysRemaining===1?"":"s"}`;
  }

  React.useEffect(()=>{
    localStorage.clear();
    if(country)localStorage.setItem("sctCountry", JSON.stringify(country));
    const getCaterers = async()=>{
      setIsLoading(true);
      // get allCaterers in Country:
      let data = await getAllCaterers();
      if(data.length>0){
        setCaterers(data);
      }
      // get config
      const config = await GetConfig();
      setOperatingCountries(config.OperatingCountries);
      // get popups
      const allPopups = await GetPopups();
      setPopups(allPopups);
      setIsLoading(false);
    }
    getCaterers();
  }, [country]);

  if(isLoading){
    return (<div className="App-body">Loading...</div>)
  }

  return (
    <div>
      <Box sx={{height: "90vh", paddingBottom: "2vmin", overflowY:"scroll"}}>
        <div className="homebg" style={{borderStyle: "outset", height: "50%", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", textAlign:"center", alignItems: "center"}}>
          <Typography variant="h4" style={{color: "#FFF", m:1}}>Find africaterers near you</Typography><br />
          <Paper component="form" sx={{ p: "2px 4px", display: "flex" }}>
            <FormControl size="small" style={{alignSelf:"center"}}>
              <Select value={country.name} onChange={handleCountryChange}>
                {operatingCountries.map((o, i)=>(
                <MenuItem key={i} value={o.name}>
                  <img
                    loading="lazy" width="20"
                    src={`https://flagcdn.com/w20/${o?.code?.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${o?.code?.toLowerCase()}.png 2x`}
                    alt={`flag of ${o.name}`}
                  />&nbsp;{o.name}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputBase
              sx={{ ml: 1, flex: 1, width: {md: "40vw"}}}
              margin = "dense"
              placeholder = "SEARCH BY NAME, ADDRESS"
              inputProps={{ "aria-label": "search caterers" }} onChange={e => setSearchParam(e.target.value)}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search" onClick={filterCaterers}><SearchIcon /></IconButton>
          </Paper>
        </div>
        {popups&&popups.length>0&&<Box sx={{p:2}}>
          <Stack direction={{ sm: "column", md: "row" }} spacing={{ xs: 1, sm: 2 }}>
            {popups.slice(0, 5).map(p=>(
              <Card key={p._id} sx={{width: {md: 300}}}>
                <CardActionArea onClick={()=>window.open("popup/"+p._id, "_blank")}>
                  <CardHeader
                    avatar={<Avatar sx={{ bgcolor: "red" }} aria-label="recipe">{p?.vendor?.businessName?.substring(0,1)}</Avatar>}
                    title={p.title.toUpperCase()}
                    subheader={<Typography variant="caption" color="error">From {getCurrency()}{p.items[0].price}</Typography>}
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary" style={{backgroundColor: "#66ff99", color: "green"}}>&nbsp;{getDaysRemaining(p.acceptUntil)} remaining&nbsp;</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Stack>
        </Box>}
        <Box sx={{p:2, backgroundColor: "#fff7e6"}}>
          <Typography style={{fontWeight: "bolder", color: "#800000"}}>HOW IT WORKS</Typography><br />
          <Stack direction={{ sm: "column", md: "row" }} spacing={{ xs: 1, sm: 2 }} justifyContent="center">
            <Chip icon={<PersonSearchIcon />} label="Find a caterer" color="success" />
            <Chip icon={<MenuBookIcon />} label="Place your order" color="error" />
            <Chip icon={<DeliveryDiningIcon />} label="Pickup or delivery on specified date" color="warning" />
            <Chip icon={<RestaurantIcon />} label="Enjoy" color="secondary" />
          </Stack>
        </Box>
        {caterers&&caterers.length>0&&<Box sx={{p:2, borderStyle: "outset"}}>
          <Typography style={{fontWeight: "bolder", color: "#332300"}}>FEATURED CATERERS</Typography>
          <Grid container spacing={2}>
            {caterers.slice(0, 8).map(c=>(
            <Grid item md={3} xs={12} key={c._id}>
              <Card>
                <CardActionArea onClick={()=>window.open("/vendor" + c.businessLink, "_blank")}>
                  <CardMedia alt={c.businessName}
                    component="img" height="100"
                    image={c?.menu[0]?.imageUrl||require("../images/rice_stew.png")}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">{c.businessName}</Typography>
                    <Typography variant="body2" color="text.secondary"><span style={{color: "orange"}}>Cuisine</span>: {c.settings.filter(a=>a.settingName==="general")[0]?.settingValue.filter(g=>g.name==="cuisine")[0]?.value}</Typography>
                    <Typography variant="caption" style={{backgroundColor: "#ffe6e6", color: "#990000", fontWeight: "bolder"}}>&nbsp;{c.settings.filter(a=>a.settingName==="orderType")[0]?.settingValue.join(" and ")}&nbsp;</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            ))}
          </Grid>
          {caterers.length>8&&<Button onClick={()=>window.open("/caterers", "_blank")} variant="contained" size="small" style={{backgroundColor: "#FFB30E"}} endIcon={<SendIcon />}>{"VIEW ALL"}</Button>}
        </Box>}
      </Box>
        {/* <span style={{ fontSize: 15 }}>Browse by Cuisine</span><br/>
        <a>West African (2)</a><br/>
        <a>East African (2)</a><br/>
        <a>Central African (2)</a><br/>
        <a>North African (2)</a><br/>
        <a>Southern African (2)</a> */}
      {/* <div>
        <Typography><span style={{ fontSize:"x-large"}}>{caterers.length}</span> Registered Caterer{caterers.length>1&&"s"}</Typography>
      </div> */}
    </div>
  );
}

export default Home;