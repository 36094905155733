import * as React from "react";
import { Typography, Box, Card, CardContent, CardHeader, Checkbox,
    Divider, Avatar, Select, MenuItem, Button, FormGroup, FormControlLabel
} from "@mui/material";
import SharedCheckoutPage from "../components/Checkout";
import { GetPopupById } from "../functions/GetPopupById";
import { useParams } from "react-router-dom";
import { GetConfig } from "../functions/GetConfig";
const Popup=()=>{
    const [orderTotal, setOrderTotal] = React.useState(0);
    const [popup, setPopup] = React.useState({});
    const [shoppingCart, setShoppingCart] = React.useState([]);
    const [settings, setSettings] = React.useState({});
    const [currency, setCurrency] = React.useState("$");
    const [isLoading, setIsLoading] = React.useState(false);

    let { id } = useParams();

    const orderOption = {
        orderType: "pickup",
        fulfillDate: new Date(popup?.date),
    }

    React.useEffect(()=>{
        const getPopup=async()=>{
            setIsLoading(true);
            const data = await GetPopupById(id);
            if(!data || !data.settings || !data.popup){
                setIsLoading(false);
                return;
            }
            data.popup.vendor.settings=data.vendorSettings;
            setPopup(data.popup);
            setSettings(data.settings);
            if(data.popup){
                const cartList = [];
                data.popup.items.forEach(item => {
                    const cart = {};
                    cart.name = item.name;
                    cart.details = item.details;
                    cart.price = parseFloat(item.price).toFixed(2);
                    cart.extras = item.extras;
                    cart.quantity = 1;

                    cartList.push(cart);
                });
                let total = cartList.reduce((a, v)=>a + parseFloat(v.price), 0);
                setShoppingCart(cartList);
                setOrderTotal(total);
                const config = await GetConfig();
                const country = config.OperatingCountries.filter(c=>c.name===data.popup?.vendor?.businessAddress?.country);
                if(country[0])setCurrency(country[0].currencySign);
            }
            setIsLoading(false);
        }
        getPopup();
    }, [id]);

    const handleChange=(qty, item)=>{
        const remainingCart = shoppingCart.filter(s=>s!==item);
        item.quantity = qty;
        remainingCart.push(item);
        getTotal(remainingCart);
        setShoppingCart(remainingCart);
    }
    const removeFromCart=(item)=>{
        const remainingCart = shoppingCart.filter(s=>s!==item);
        getTotal(remainingCart);
        setShoppingCart(remainingCart);
    }

    const getTotal=(cart)=>{
        let total = 0;
        cart.forEach(item=>{
            let itemTotal = parseFloat(item.price*item.quantity);
            if(item.extras){
                item.extras.forEach(extra=>{
                    if(item.addedExtras && item.addedExtras.includes(extra.item))itemTotal+=parseFloat(extra.additionalPrice*item.quantity);
                })
            }
            total+=itemTotal;
        });
        setOrderTotal(total);
    }

    const updateExtras=(item, extra, isChecked)=>{
        let cart = shoppingCart.filter(s=>s!==item);
        let additionalItems = item.addedExtras||[];
        if(isChecked){
            //add
            additionalItems.push(extra.item);
        }
        else{
            // remove
            additionalItems = additionalItems.filter(a=>a!==extra.item);
        }
        item.addedExtras = additionalItems;
        cart.push(item);
        getTotal(cart);
        setShoppingCart(cart);
    }

    if(isLoading){
        return (<div className="App-body">Loading...</div>)
    }
    if(!popup.items){
        return (<div className="App-body">Popup does not exist</div>)
    }
    if(!["Verified"].includes(popup.vendor.accountStatus)){
        return (<div className="App-body">Invalid Popup</div>)
    }
    return (
    <div style={{height: "90vh"}}>
        <Box sx={{
            backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            m: 2,
            gap: 4,
            display: "grid",
            height: "100%",
            gridTemplateColumns: { md: "1fr 2fr" },
            overflowY: "scroll",
            overflowX: "hidden"
        }}>
            <div>
                <Typography gutterBottom variant="h5" component="div">*** {popup?.title?.toUpperCase()} ***</Typography>
                <Typography variant="body2" color="text.secondary">{popup?.vendor?.businessName}</Typography>
                <Typography variant="body2" color="text.secondary">{popup?.vendor?.phone}</Typography>
                <Typography variant="body2" color="text.secondary">{popup?.vendor?.businessAddress?.street} {popup?.vendor?.businessAddress?.apt} {popup?.vendor?.businessAddress?.city} {popup?.vendor?.businessAddress?.state} {popup?.vendor?.businessAddress?.zip}</Typography>
                <Divider color="success" style={{backgroundColor:"#00043b", height:5}}/>
                <Typography variant="body2" color="text.secondary" align="left"><span style={{fontWeight: "bolder"}}>Pickup Date:</span> {new Date(popup?.date).toLocaleString("en-US", { dateStyle: "full", timeStyle: "short" })}</Typography>
                {shoppingCart.map((item, i)=>(
                <Card key={i} sx={{ textAlign:"left" }}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: "maroon" }}>{item?.name?.substr(0, 1)}</Avatar>
                        }
                        action={
                            <Typography>{currency}{item.price}</Typography>
                        }
                        title={item.name}
                        subheader={item.details}
                    />
                    <CardContent>
                        <Select size="small"
                            inputProps={{ "data-testid": "quantity" }}
                            value={item.quantity}
                            labelId="quantity"
                            onChange={(e)=>handleChange(e.target.value, item)}
                        >
                            {[...Array(5).keys()].map(i => (
                                <MenuItem key={i} value={i+1}>{i+1}</MenuItem>
                            ))}
                        </Select>
                        <FormGroup>
                            {item.extras&&item.extras.length>0&&item.extras.map(extra=>(
                                <FormControlLabel key={extra.item} checked={(item.addedExtras)?item.addedExtras.includes(extra.item.trim()):false} control={<Checkbox />} label={`Add ${extra.item} (+${currency}${extra.additionalPrice})`} onChange={(e)=>updateExtras(item, extra, e.target.checked)} />
                            ))}
                        </FormGroup>
                        {shoppingCart.length>1&&<Button data-testid={i} onClick={() => removeFromCart(item)}>Delete</Button>}
                    </CardContent>
                </Card>))}
                <br />
                <Divider color="error" /><Divider color="error"/>        
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography variant="h5">TOTAL</Typography>
                    <Typography variant="h5" data-testid="total">{currency}{orderTotal}</Typography>
                </div>
                <Divider style={{backgroundColor: "#000", height:10}} />
            </div>
            <div>
                {new Date()<new Date(popup.acceptUntil)?
                <SharedCheckoutPage vendor={popup?.vendor} settings={settings} currency={currency} cart={shoppingCart} orderOption={orderOption} orderTotal={orderTotal} serviceType="popup" />
                :
                <Typography variant="h3" component="div">We're no longer accepting orders for "{popup?.title}".</Typography>
                }
            </div>
        </Box>
    </div>
    );
}

export default Popup;