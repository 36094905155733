export const PlaceOrder = async(orderDet)=>{
    const res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },      
        body: JSON.stringify(orderDet),
    });
    if(res.ok){
        const orderId = await res.json();
        // localStorage.setItem("orderId", orderId);
        return orderId;
    }
    return null;
}