import * as React from "react";
import "./App.css";
import NavHeader from "./components/Header";
import NavFooter from "./components/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Caterers from "./pages/Caterers";
import Home from "./pages/Home";
import OrderDetails from "./pages/Order";
import NotFound from "./pages/NotFound";
import ShopCheckout from "./pages/ShopCheckout";
import Popup from "./pages/Popup";
import VendorProfile from "./pages/VendorProfile";

const App=()=> {
  return (
    <div className="App">
      <div><NavHeader/></div>
      <BrowserRouter>
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route path="/caterers" element={<Caterers />} />
          <Route path="/order/:id" element={<OrderDetails />} />
          <Route path="/checkout" element={<ShopCheckout />} />
          <Route path="/popup/:id" element={<Popup />} />
          <Route path="/vendor/:id" element={<VendorProfile />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      </BrowserRouter>
      <div className="App-footer"><NavFooter/></div>
    </div>
  );
}

export default App;