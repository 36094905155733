const getCoords=async(address)=>{
    let coords = {};
    await fetch(`${process.env.REACT_APP_RADAR_API_URL}geocode/forward?query=${address}`, {
        method: "GET",
        headers: {
          "Authorization": `${process.env.REACT_APP_RADAR_API_KEY}`,
        }
    })
    .then(response=>response.json())
    .then(data=>{
        coords.lat = data.addresses[0].latitude;
        coords.long = data.addresses[0].longitude;
    })
    .catch(error=>{
        console.log(error, "Error getting coords");
        coords = null;
    });
    return coords;
}

export default getCoords;