import * as React from "react";
import { 
    Box, Typography, Tabs, Tab, Grid, Divider, Card, CardContent,
    Rating, CardMedia
} from '@mui/material';
import { useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import { GetCatererMenu } from "../functions/GetCatererMenu";
import { VendorItem } from "../components/VendorItem";

const VendorProfile=()=>{
    const [item, setItem] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [group, setGroup] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [vendorData, setVendorData] = React.useState(null);
    const [acceptedPayment, setAcceptedPayment] = React.useState("");
    const [reviews, setReviews] = React.useState([]);
    const [summaryRating, setSummaryRating] = React.useState({});

    let { id } = useParams();

    const handleGroupChange=(e, newValue)=>{
        setGroup(newValue);
        setItem(newValue==="Catering"?vendorData.menu:vendorData.popup);
    }

    React.useEffect(()=>{
        const getVendorProfile=async()=>{
            let data = await GetCatererMenu(id);
            const vendor = data[0];
            setVendorData(vendor);
            if(vendor){
                let tabs = vendor.serviceType === "Catering" && ["Catering", "Popup"];
                tabs.sort();
                setGroups(tabs);
                setGroup(tabs[0]);

                const paymentSettings = Object.keys((vendor.settings
                    .filter(a=>a.settingName === "paymentType")[0]?.settingValue)
                    ||"")
                    .map(p=>p).join(", ");
                setAcceptedPayment(paymentSettings);

                setItem(tabs[0]==="Catering"?vendor.menu:vendor.popup);
                const review = vendor.vendorOrders||[];
                setReviews(review);
                const rating = {};
                const len = review.length||0;
                const total = review.reduce((a, v)=>a + v.reviews.rating, 0)||0;
                rating.count = len + " rating" + (len===1?"":"s");
                rating.avg = parseFloat(total/len).toFixed(2)||0;
                setSummaryRating(rating);
            }
            setIsLoading(false);
        }
        getVendorProfile();
    },[id]);

    if(isLoading)return(<div className="App-body">Loading...</div>)
    if(!vendorData)return(<div className="App-body">Invalid Vendor</div>)

    return(
        <div className="App-body">
            <div style={{ padding: 15, overflowY: "scroll", height: "100%", width: "100%" }}>
            <Card sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto", textAlign: "left" }}>
                    <Typography variant="h3" style={{ color: "green" }}>{vendorData.businessName}</Typography>
                        <Typography>{parseFloat(summaryRating.avg)||0}
                            <Rating size="small" precision={0.1} value={parseFloat(summaryRating.avg)||0} readOnly/>
                            {" | " + summaryRating.count}
                        </Typography>
                        <Divider /><Divider />
                        <Typography style={{ fontSize: 16}}>
                            <CallIcon fontSize="small" color="error" /> {vendorData.phone || ""} <br />
                            <HomeIcon fontSize="small" color="error" /> {`${vendorData.businessAddress?.street?.toUpperCase()||""} ${vendorData.businessAddress?.apt?.toUpperCase()||""} ${vendorData.businessAddress?.city.toUpperCase()}, ${vendorData.businessAddress?.state.toUpperCase()}, ${vendorData.businessAddress?.zip}`}
                        </Typography>
                        <Divider />
                        {acceptedPayment&&<Typography>
                            <span style={{ textDecoration: "underline", fontWeight: "bold"}}>Accepted Payments: </span><br />{acceptedPayment}
                        </Typography>}
                    </CardContent>
                </Box>
                <CardMedia
                    component="img"
                    sx={{ height:{md: 250}, width:{sm: "100vw"} }}
                    image="/images/bg.png"
                    alt="This is a banner. The background is black; there is an egg roll cut in half on the top left corner and rice and stew served on a leaf on the bottom right corner."
                />
            </Card>
            <Box sx={{
                    backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                    m: 2,
                    gap: 4,
                }}>
                <div>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={group} textColor="secondary" indicatorColor="secondary" onChange={handleGroupChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            {groups.map(g=>(
                            <Tab key={g} label={g + " Menu"} value={g} />
                            ))}
                        </Tabs>
                    </Box><br/>
                    <VendorItem item={item} tab={group} vendor={vendorData}/>
                </div>  
            </Box>
            {reviews&& reviews.length>0&&
            <Box sx={{p:2, textAlign: "left", backgroundColor: "#FFFDFC"}}>
                <br /><br />
                <Typography variant="h3" textAlign="center">Reviews</Typography>
                <Grid container spacing={2}>
                {reviews.slice(0, 5).map((r, i)=>(<Grid key={i} item xs={12} sm={4}>
                    <Box sx={{ p: 2, textAlign: "center", minWidth: 240,
                        minHeight:240, alignContent: "center", boxShadow: "1px 2px 9px #F4AAB9"
                    }}>
                        <Rating name="app-rating" value={r.reviews.rating} precision={0.1} readOnly/>
                        <Typography component="legend" style={{fontStyle: "italic"}}>{r.reviews.review}</Typography><br />
                        <Typography variant="caption">{r.reviews.reviewer}</Typography><br />
                    </Box>
                </Grid>))}
                </Grid>
            </Box>}
        </div>
    </div>
    )
};

export default VendorProfile;