import * as React from "react";

const NotFound=()=> {
    return(
    <div className="App-body">
        <div id="message">
            <h2>404</h2>
            <h1>Page Not Found</h1>
            <p>Please check the URL for mistakes and try again.</p>
        </div>
    </div>
    );
}
export default NotFound;

