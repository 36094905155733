import * as React from "react";
import SharedCheckoutPage from "../components/Checkout";
import { Box, Button, Divider, Typography } from "@mui/material";

const Checkout=(props)=>{
    const {caterer, currency} = props;
    const [orderTotal, setOrderTotal] = React.useState(0.00);
    const orderOption = JSON.parse(localStorage.getItem("options"));
    const cart = JSON.parse(localStorage.getItem(caterer.businessName + "_cart"));

    React.useEffect(()=>{
        let tots = cart.reduce((a, v)=>a + v.price, 0);
        if(cart)setOrderTotal(tots.toFixed(2));
    }, [cart]);

    if(!orderOption){
        return(<></>)
    }

    return (
        <div style={{height: "90vh"}}>
            <Box sx={{
                backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                m: 2,
                gap: 4,
                display: "grid",
                height: "100%",
                gridTemplateColumns: { md: "2fr 1fr" },
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <div>
                    <SharedCheckoutPage vendor={caterer} currency={currency} cart={cart} orderOption={orderOption} serviceType={"catering"} orderTotal={orderTotal} />
                </div>
                <div>
                    <Box sx={{ width: '100%', boxShadow:2, m:2, p:2 }}>
                        <Typography variant="subtitle">Order Summary</Typography><Divider/>
                        <div style={{ textAlign: "left"}}>
                            <Typography><span style={{ fontWeight: "bold"}}>Type:{" "}</span>{orderOption.orderType}</Typography>
                            <Typography><span style={{ fontWeight: "bold", textTransform: "capitalize"}}>{orderOption.orderType} Date:{" "}</span>{new Date(orderOption.fulfillDate).toLocaleString([], {dateStyle: "long", timeStyle: 'short'})}</Typography>
                        </div>
                        <Divider/>
                        <div>
                            <table className="table table-bordered" style={{ fontSize: 14}}>
                                <tbody>
                                    {cart.map((o, i) => (
                                    <tr key={i}>
                                        <td>{o.quantity}{" "}{o.food}<br />
                                        <span style={{fontSize: 10}}>serving: {o.serving}</span>
                                        {o.subs.length>0&&<span style={{fontSize: 10}}><br />with: {o.subs.map(s=>s.item).join()}</span>}
                                        </td>
                                        <td>{currency}{parseFloat(o.price).toFixed(2)}</td>
                                    </tr>))}
                                    <tr>
                                        <td colSpan={2} style={{fontWeight: "bold", textAlign: "right"}}>Total: {currency}{orderTotal}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Box>
                    <Box sx={{ width: '100%', m:2, textAlign: "left" }}>
                        <Button variant="outlined" color="success" onClick={()=>window.location.reload(true)}>CONTINUE SHOPPING</Button>
                    </Box>
                </div>
            </Box>
        </div>
    );
};

export default Checkout;