import * as React from "react";
import { Box } from '@mui/material';
import { OrderPopup } from "./OrderPopup";
import { OrderCatering } from "./OrderCatering";

export const VendorItem=({item, tab, vendor})=>{
    return(
    <Box sx={{
        backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        gap: 4,
        display: 'grid',
    }}>
        {item.length<=0?
            <div style={{ padding: 15, overflowY: "scroll", height: "100%", width: "100%" }}>
                No Menu Available
            </div>
            :
            <div>
                {tab.toLowerCase()==="popup"?<OrderPopup item={item} />:<OrderCatering item={item} vendor={vendor} />}
            </div>
        }
    </Box>
    )
}