import * as React from "react";
import { 
    Box, Typography, Modal, Tab, Grid, Button, ButtonBase, Divider,
    RadioGroup, Radio, FormControlLabel, TextField, Checkbox, Tabs
} from '@mui/material';
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OptionsFormGroup from "./OptionsFormGroup";
import Checkout from "../pages/Checkout";

export const OrderCatering=({item, vendor})=>{
    const [order, setOrder] = React.useState(JSON.parse(localStorage.getItem(vendor.businessName + "_cart"))||[]);
    const [group, setGroup] = React.useState(item[0].group);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [substitutes, setSubstitutes] = React.useState([]);
    const [totalNow, setTotalNow] = React.useState(0);
    const [selectedServing, setSelectedServing] = React.useState({});
    const [selectedSub, setSelectedSub] = React.useState([]);
    const [editOrder, setEditOrder] = React.useState(false);
    const [isCheckout, setIsCheckout] = React.useState(false);
    const [dateError, setDateError] = React.useState(null);
    const currency = JSON.parse(localStorage.getItem("sctCountry")).currencySign||"$";

    const getGroups=()=>{
        // let caterer = {};
        // caterer.phone = vendor.phone;
        // caterer.businessName = vendor.businessName;
        // localStorage.setItem("caterer", JSON.stringify(caterer));

        let groups = [];
        item.forEach(m=>{
            if(!groups.includes(m.group))groups.push(m.group);
        });
        return groups;
    }

    const handleServingChange=(item)=>{
        setSelectedServing(item);
        let subPrice = selectedSub?(selectedSub.reduce((a, v) => a = a + v.addPrice, 0)):0;
        setTotalNow(selectedItem.quantity * (parseFloat(item.price) + subPrice));
    }

    const handleGroupChange=(e, newValue)=>{
        setGroup(newValue);
    }

    const handleSubstituteChange=(sub, isChecked)=>{
        let sctSub = [...selectedSub];
        let ind = sctSub.findIndex(a=>a.item===sub.item);
        if(ind!==-1)sctSub.splice(ind, 1);
        if(isChecked)sctSub.push(sub);
        setSelectedSub(sctSub);
        let subPrice = sctSub.reduce((a, v) => a = a + v.addPrice, 0);
        setTotalNow(selectedItem.quantity * (parseFloat(selectedServing.price) + subPrice));
    }

    const handleAddToCart=(selected)=>{
        selected.quantity = 1;
        let svs = selected.servings;
        let svSetting = JSON.parse(localStorage.getItem("servingSize"));
        if(svSetting && svSetting.length>0){
            let newsvs=[];
            svs.forEach(s=>{
                const st = svSetting.filter(sv=>sv.serving===s.serving);
                if(st.length>0){
                    s.size = `(serves ${st[0].size})`;
                }
                newsvs.push(s);
            });
            selected.servings = newsvs;
        }
        setSelectedItem(selected);
        let subs = selected.servings[0].substitutes;
        setSubstitutes(subs);
        setSelectedServing(selected.servings[0]);
        let subPrice = 0;
        setSelectedSub([]);
        setTotalNow(selected.quantity*(parseFloat(selected.servings[0].price) + subPrice));
        setModalOpen(true);
    }

    const addToCart=()=>{
        let orders = order;
        if(editOrder){
            orders = JSON.parse(localStorage.getItem("cart2"));
        }
        let res = {};
        res.quantity = selectedItem.quantity;
        res.food = selectedItem.food;
        res.serving = selectedServing.serving;
        res.subs = selectedSub;
        res.price = totalNow;
        orders.push(res);
        setOrder(orders);
        localStorage.setItem(vendor.businessName+"_cart", JSON.stringify(orders));
        localStorage.removeItem("cart2");
        setModalOpen(false);
        setEditOrder(false);
    }

    const updateCartItem=(i)=>{
        let res = [...order];
        let servings = item.filter(m=>m.food === res[i].food)[0]?.servings;
        if(servings){
            res[i].servings = servings;
            let sctServing = servings.filter(s=>s.serving===res[i].serving);
            setSelectedServing(sctServing[0]);
            setSubstitutes(sctServing[0].substitutes);
            setSelectedSub(res[i].subs);
        }
        setSelectedItem(res[i]);
        setTotalNow(res[i].price);
        setModalOpen(true);
        setEditOrder(true);
        let cart2 = res;
        cart2.splice(i, 1);
        localStorage.setItem("cart2", JSON.stringify(cart2));
    }

    const closeModal=()=>{
        localStorage.removeItem("cart2");
        setModalOpen(false);
        setEditOrder(false);
    }

    const deleteCartItem=(i)=>{
        let res = [...order];
        res.splice(i, 1);
        localStorage.setItem(vendor.businessName + "_cart", JSON.stringify(res));
        setOrder(res);
    }

    const clearCart=()=>{
        localStorage.removeItem(vendor.businessName + "_cart");
        setOrder([]);
    }

    const increaseQuantity=()=>{
        let selected = selectedItem;
        selected.quantity++;
        setSelectedItem(selected);
        let subPrice = selectedSub?(selectedSub.reduce((a, v) => a = a + v.addPrice, 0)):0;
        setTotalNow(selected.quantity * (parseFloat(selectedServing.price) + subPrice));
    }

    const decreaseQuantity=()=>{
        let selected = selectedItem;
        if(selected.quantity>1)selected.quantity--;
        setSelectedItem(selected);
        let subPrice = selectedSub?(selectedSub.reduce((a, v) => a = a + v.addPrice, 0)):0;
        setTotalNow(selected.quantity * (parseFloat(selectedServing.price) + subPrice));
    }

    const handleCheckout=()=>{
        let opt = JSON.parse(localStorage.getItem("options"));
        if(opt && opt.fulfillDate){
            let hols = vendor.settings.filter(a=>a.settingName === "holidays");
            if(!hols[0]){
                setDateError(null);
                setIsCheckout(true);
            }else{
                hols[0].settingValue.forEach(s => {
                    if(new Date(opt.fulfillDate)>=new Date(s.DateFrom) &&
                        new Date(opt.fulfillDate)<=new Date(s.DateTo))
                    {
                        setDateError("Caterer is not available on this date. Please select a different date. Thank you!!");
                    }
                    else{
                        setDateError(null);
                        setIsCheckout(true);
                    }
                });
            }
        }
    }

    if(isCheckout)return (<Checkout caterer={vendor} currency={currency}/>);
    return(
        <div>
            <Box>
                <Tabs sx={{ border: 1, borderColor: 'divider' }} value={group} textColor="inherit" onChange={handleGroupChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    {getGroups().map(g=>(
                    <Tab key={g} label={g} value={g} />
                    ))}
                </Tabs>
            </Box><br/>
            <Box sx={{
                p: 2, gap: 4, display: 'grid', gridTemplateColumns: { md: '2fr 1fr' },
            }}>
                <Box>
                    {item.filter(m=>m.group===group).map((c, i)=>(
                    <Grid key={i} container spacing={2} sx={{ boxShadow: 2, p:1, mb:2 }}>
                        <Grid item>
                            <ButtonBase sx={{ width: 68, height: 68 }}>
                                <img alt="food" src={c.imageUrl||require("../images/noimage.jfif")} width='100%' display="block" margin="auto" />
                            </ButtonBase>
                        </Grid>
                        <Grid item xs>
                            <Typography style={{ textAlign: 'right', fontWeight: 'bolder', fontSize: 10, color: '#800000' }}>
                                {currency}{c.servings[0]?.price}
                            </Typography>
                            <Typography variant="h4" component="div" color="primary">{c.food}</Typography>
                            <Typography variant="caption" gutterBottom>{c.description}</Typography>
                            <Grid item>
                                <Button variant="outlined" color="success" size="small" onClick={()=>handleAddToCart(c)}>{vendor.accountStatus==="Read Only"?"VIEW OPTIONS":"ADD TO CART"}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    ))}
                </Box>
                <Box>
                    {vendor.accountStatus!=="Read Only"&&
                    <Box sx={{ boxShadow:2, p:2 }}>
                        <Typography variant="title">Your Order</Typography><Divider />
                        <div>
                            {order.length===0?
                            <Typography style={{ fontSize: 10 }}>No Items in Cart</Typography>:
                            <div>
                            <Button onClick={clearCart} size="small">Clear Cart</Button>
                            <table className="table table-striped" style={{ fontSize: 14}}>
                                <tbody>
                                    {order.map((o, i) => (
                                    <tr key={i}>
                                        <td>{o.quantity}</td>
                                        <td>{o.food}<br />
                                            <span style={{fontSize: 10}}>serving: {o.serving}</span>
                                            {o.subs.length>0&&<span style={{fontSize: 10}}><br />with: {o.subs.map(s=>s.item).join()}</span>}
                                        </td>
                                        <td>{currency}{parseFloat(o.price).toFixed(2)}</td>
                                        <td>
                                            <IconButton size="small" onClick={()=>updateCartItem(i)}><EditIcon /></IconButton>
                                            <IconButton size="small" onClick={()=>{deleteCartItem(i)}}><DeleteIcon /></IconButton>
                                        </td>
                                    </tr>))}
                                </tbody>
                            </table>
                            </div>}
                        </div>
                        <Divider color="success"/><Divider color="error"/>
                        <div>
                            <Typography variant="subtitle">Order Options</Typography><Divider/>
                            <OptionsFormGroup catererDets={vendor}/>
                        </div>
                        {dateError&&<Typography variant="caption" color="error">{dateError}</Typography>}<br />
                        {order.length>0&&<Button variant="outlined" color="secondary" onClick={handleCheckout}>Checkout</Button>}
                    </Box>
                }
            </Box>
        </Box>
    <Modal
        open={modalOpen}
        onClose={()=>setModalOpen(false)}>
            <Box sx={{ 
                width:{xs:"100%", md:500}, boxShadow: 2, p:4,
                bgcolor: 'background.paper', border: '2px solid #000', 
                position: 'absolute', top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <Typography variant="h6" component="h2" align="center">
                    {selectedItem.food}
                </Typography>
                <Typography align="right" color="error">
                    {currency}{parseFloat(totalNow).toFixed(2)}
                </Typography>
                <Typography style={{ fontWeight: "bold" }}>Quantity:</Typography>
                <Button variant="contained" color="error" onClick={decreaseQuantity}>-</Button>{" "}
                <TextField size="small" style={{width: 50 }} disabled value={selectedItem.quantity} />{" "}
                <Button variant="contained" color="success" onClick={increaseQuantity}>+</Button>
                <br />
                {selectedItem.servings&&(
                    <>
                    <Typography style={{ fontWeight: "bold" }}>Select Serving:</Typography>
                    <RadioGroup value={selectedServing.serving}>
                        {selectedItem.servings.map((s, i) => (
                            <FormControlLabel key={i} value={s.serving} control={<Radio />} label={`${s.serving} ${s.size||""}`} onChange={()=>handleServingChange(s)}/>
                        ))}
                    </RadioGroup>
                    {substitutes&&substitutes.length>0&&(
                        <>
                            <Typography style={{ fontWeight: "bold" }}>Options:</Typography>
                            {substitutes.map((d, i)=>(
                                <div key={i}>
                                    {selectedSub.item}
                                    <FormControlLabel control={<Checkbox onChange={(e) => handleSubstituteChange(d, e.target.checked)} checked={selectedSub.length>0&&selectedSub.some(s=>s.item===d.item)} />} label={d.item} />
                                </div>
                            ))}
                        </>
                    )}
                    </>
                )}
                <br/>
                {vendor.accountStatus!=="Read Only" && editOrder&&
                <Button variant="contained" color="secondary" size="small" onClick={()=>addToCart()}>Update</Button>}
                {vendor.accountStatus!=="Read Only" && !editOrder&&
                <Button variant="contained" color="secondary" size="small" onClick={()=>addToCart()}>Add</Button>
                }{" "}
                <Button variant="contained" style={{backgroundColor: "#1c0000"}} onClick={closeModal} size="small">Close</Button>
            </Box>
        </Modal>
    </div>   
    )
}