import * as React from "react";
import { 
    CNavbar, CContainer, CNavbarBrand, CNavItem, CNavLink,
    CNavbarToggler, CNavbarNav,  CCollapse, CButton
} from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';

const Header=()=>{
    const [visible, setVisible] = React.useState(false);

    return (
    <div className="App-header">
        <CContainer>
        {/* <CNavbar expand="lg" colorScheme="dark" style={{fontWeight: "bolder", backgroundColor: "#006644"}} placement="fixed-top"> */}
        <CNavbar expand="lg" colorScheme="light" style={{ backgroundColor: "#FFF", fontWeight: "bolder" }} placement="fixed-top">
            <CContainer fluid>
                {/* <CNavbarBrand href="/" className="navbarBrand"> */}
                <CNavbarBrand href="/">
                <img src="/main/android-chrome-192x192.png"
                    alt="Logo" loading="lazy" width="28" height="28"
                    className="d-inline-block align-top"
                /><span style={{fontFamily: "serif", color: "#004D33"}}>Africaterer</span>
                </CNavbarBrand>
                <CNavbarToggler onClick={() => setVisible(!visible)}/>
                <CCollapse className="navbar-collapse" visible={visible}>
                    <CNavbarNav className="me-auto mb-2 mb-lg-0">
                        <CNavItem><CNavLink href="/caterers" active={window.location.pathname.includes("caterers")}>Our Caterers</CNavLink></CNavItem>
                        {/* <CNavItem><CNavLink href="/shop" active={window.location.pathname.includes("shop")}>Shop</CNavLink></CNavItem> */}
                    </CNavbarNav>
                    <CNavbarNav>
                        <CNavItem>
                            <CButton href={process.env.REACT_APP_CATERER_URL+"/login"} variant="outline" style={{backgroundColor: "#800000", color: "#fff"}}>Vendor Login</CButton>
                        </CNavItem>
                    </CNavbarNav>
                </CCollapse>
            </CContainer>
        </CNavbar></CContainer>
    </div>
  );
}
export default Header;