export const getAllCaterers = async()=>{
  const country = JSON.parse(localStorage.getItem("sctCountry"));
  let theCountry = country?.name;
  if(!theCountry){
    localStorage.setItem("sctCountry", JSON.stringify({name: "USA", code: "US", currencySign:"$", currencyCode: "USD"}));
    theCountry = "USA";
  }
  const res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/vendors?country=${theCountry?.trim()}`);
  if(res.ok)return await res.json();
  return [];
}