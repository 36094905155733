import * as React from "react";
import CatererList from "../components/CatererList.js";
import { getAllCaterers } from "../functions/GetCaterers.js";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const Caterers=()=>{
    const location = useLocation();
    const [caterers, setCaterers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    
    React.useEffect(()=>{
        const findCaterers=async()=>{
            setIsLoading(true);
            const data = await getAllCaterers();
            setCaterers(data);
            setIsLoading(false);
        }
        if(!location.state){
            findCaterers();
        }
    }, [location]);

    if(isLoading){
        return (
            <div style={{height: "90vh", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <CircularProgress sx={{ color: "grey.900" }} size={170} />
            </div>
        );
    }
    return (
        <CatererList caterers={caterers} />
    );
}
export default Caterers;