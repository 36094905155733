const SearchAddress=async(address)=>{
    let addresses = [];
    await fetch(`${process.env.REACT_APP_RADAR_API_URL}search/autocomplete?query=${address}&limit=2`, {
        method: "GET",
        headers: {
          "Authorization": `${process.env.REACT_APP_RADAR_API_KEY}`,
        }
    })
    .then(response=>response.json())
    .then(data=>{
        addresses = data.addresses;
    })
    .catch(error=>{
        console.log(error, "Error getting address");
        addresses = null;
    });
    return addresses;
}

export default SearchAddress;