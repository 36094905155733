export const PopulateOrderObject = async(
    currency, vendor, order, cart, customerName, customerPhone, customerEmail, orderOption, amount, paymentType, customerAddress, serviceType
)=>{
    order.currency = currency;
    order.status = "pending";
    order.customerName = customerName;
    order.customerPhone = customerPhone;
    order.customerEmail = customerEmail;
    order.orderType = orderOption.orderType;
    order.customerNote = "";
    order.orderFulfillDate = orderOption.fulfillDate;
    order.total = amount;
    order.paymentType = paymentType;
    order.customerAddress = customerAddress;
    let transfers = [];
    // from shopCheckout
    if(serviceType==="shop"){
        cart.forEach(c=>{
            const newTransfer = {};
            let amount = parseFloat(c.total.substring(1));
            // let shopTotal = (c.shopTotal)?parseFloat(c.shopTotal):0;
            const existingVendor = transfers.filter(t=>t.vendor._id === c.vendor._id);
            if(existingVendor.length>0){
                // remove existing vendor from transfer
                const itemToRemove = transfers.indexOf(existingVendor[0]);
                if(itemToRemove!==-1)transfers.splice(itemToRemove, 1);
                // update amount, add details
                const newTotal = amount + existingVendor[0].orderTotal;
                newTransfer.orderTotal = newTotal;
                // const newShopTotal = shopTotal + existingVendor[0].shopTotal;
                // newTransfer.shopTotal = newShopTotal;
                const orderDetails = existingVendor[0].orderDetails||[];
                const newDetail = {"name": c.name, "quantity": c.quantity, "serving": c.sizes?.size, "price": c.total.substring(1)};
                orderDetails.push(newDetail);
                newTransfer.orderDetails = orderDetails;
            }
            else {
                newTransfer.orderTotal = amount;
                // newTransfer.shopTotal = shopTotal;
                newTransfer.orderDetails = [{"name": c.name, "quantity": c.quantity, "serving": c.sizes?.size, "price": c.total.substring(1)}];
            }
            newTransfer.vendor = c.vendor;
            transfers.push(newTransfer);
            
        });
        if(transfers.length>1)order.splitPayment = true;
    }
    else{
        const newTransfer = {};
        newTransfer.vendor = vendor;
        newTransfer.orderDetails = cart;
        newTransfer.orderTotal = amount;
        transfers.push(newTransfer);
    }
    order.transfers = transfers;
    return order;
}