import React from "react";
import {useStripe, useElements, PaymentElement, AddressElement} from "@stripe/react-stripe-js";
import { FormControl, Button, TextField } from "@mui/material";
import { PlaceOrder } from "../../functions/PlaceOrder";
import ValidateCustomerInfo from "../../functions/ValidateCustomerInfo";
import { PopulateOrderObject } from "../../functions/PopulateOrderObject";

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const {currency, paymentType, amount, cart, orderOption, vendor, serviceType} = props;
    const [customerPhone, setCustomerPhone] = React.useState("");
    const [customerEmail, setCustomerEmail] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [customerAddress, setCustomerAddress] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const handleError=(errorMsg)=>{
        setLoading(false);
        setErrorMessage(errorMsg);
    }

    const placeOrder = async(order)=>{
        const orderData = await PopulateOrderObject(currency, vendor, order, cart, customerName, customerPhone, customerEmail, orderOption, amount, paymentType, customerAddress, serviceType);
        const res = await PlaceOrder(orderData);
        orderData.orderId = res;
        return orderData;
    }

    const makePayment = async(e)=>{
        e.preventDefault();
        if (!stripe) {
            return;
        }
        const {error: submitError} = await elements.submit();

        setLoading(true);

        const errMsg = await ValidateCustomerInfo(customerName, customerPhone, customerEmail);
        if(errMsg!==null){
            handleError("Missing or invalid fields. Please check and try again");
            return;
        }

        if (submitError) {
            handleError(submitError);
            return;
        }

        let order = {};
        order.cardAmount = parseInt(amount*100);

        order = await placeOrder(order);
        let orderId = order.orderId;
        order.transferGroup = orderId;
        const res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(order),
        });

        const {client_secret: clientSecret} = await res.json();
        await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: `${window.location.origin}/order/${orderId}`,
            },
        })
        .then(function(result) {
            if (result.error) {
                console.log("i have error");
                handleError(result.error);
            }
            else{
                // Your customer is redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer is redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
                console.log("all good");
            }
        });
    }

    return (
    <form onSubmit={makePayment}>
        <PaymentElement />
        <AddressElement options={{mode: "shipping"}} onChange={(event) => {
            if (event.complete) {
                const address = event.value.address;
                let addressString = "";
                if(orderOption.orderType === "delivery"){
                    addressString = `${address.line1||""} ${address.line2||""} ${address.city||""} ${address.state||""} ${address.postal_code||""} ${address.country||""}`;
                }
                setCustomerName(event.value.name);
                setCustomerAddress(addressString);
            }
        }} />
        <FormControl fullWidth>
            <TextField label="Phone" size="small" margin="dense" inputProps={{ maxLength: 10 }} onChange={(e) => setCustomerPhone(e.target.value)} />
            <TextField label="Email" size="small" margin="dense" onChange={(e) => setCustomerEmail(e.target.value)} />
        </FormControl>                   
        <Button variant="outlined" color="secondary" type="submit" disabled={!stripe || loading}>Place Order</Button>
        {errorMessage && <div style={{ color: "red", fontSize: 13}}>{errorMessage}</div>}
    </form>
    );
};

export default CheckoutForm;