import * as React from "react";
import { TextField, Select,FormGroup, MenuItem
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

const miniTime = dayjs().set('hour', 8).startOf('hour');
const maxiTime = dayjs().set('hour', 20).startOf('hour');
const miniDate = dayjs().add(2, 'day').set('hour', 8);

const OptionsFormGroup = (props) => {
    const { catererDets } = props;
    const [orderType, setOrderType] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [orderOptions, setOrderOptions] = React.useState({});

    React.useEffect(() => {
        setIsLoading(true);
        let dorderType = catererDets.settings.filter(a=>a.settingName === "orderType")[0].settingValue;
        let svs = catererDets.settings.filter(a=>a.settingName === "servings")[0].settingValue;
        localStorage.setItem("servingSize", JSON.stringify(svs));
        let opt = JSON.parse(localStorage.getItem("options"));
        let defDate = miniDate;
        if(opt && opt.fulfillDate)defDate=opt.fulfillDate;
        setOrderType(dorderType);
        let opts={};
        let selectedOrderType = dorderType[0];
        if(opt && opt.orderType)selectedOrderType=opt.orderType;
        opts.orderType = selectedOrderType;
        opts.fulfillDate = defDate;
        opts.orderDate = new Date();
        localStorage.setItem("options", JSON.stringify(opts));
        setOrderOptions(opts);
        setIsLoading(false);
    }, [catererDets]);

    const store=(opt, val)=>{
        let s = orderOptions;
        s[opt] = val;
        localStorage.setItem("options", JSON.stringify(s));
    }

    const getHolidayDates=(from, to)=>{
        const today = new Date(from);
        const endDate = new Date(to);
        let blockedDates = [];
        while (today <= endDate) {
            blockedDates.push(today.toJSON().slice(0, 10));
            today.setDate(today.getDate() + 1);
        }
        return blockedDates;
    }

    const getDaysOff=(currDate, daysOff)=>{
        const today = new Date(currDate);
        const wkday = today.toLocaleString('en-us', {  weekday: 'long' });
        let blockedDates = [];
        daysOff.forEach(day=>{
            if(wkday===day){
                blockedDates.push(today.toJSON().slice(0, 10));
            }
        });
        return blockedDates;
    }

    const getDisabledDates=(date) => {
        let cDate = new Date(date).toJSON().slice(0,10);
        let blackoutDates = [];
        let hols = catererDets.settings.filter(a=>a.settingName === "holidays");
        if(hols[0]){
            hols[0].settingValue.forEach(s => {
                blackoutDates.push(getHolidayDates(s.DateFrom, s.DateTo));
            });
        }
        let workOff = catererDets.settings.filter(a=>a.settingName === "workDays");
        if(workOff[0]){
            blackoutDates.push(getDaysOff(date, workOff[0].settingValue));
        }
        return [].concat(...blackoutDates).includes(cDate);
    }

    if(!catererDets)return (<></>)
    if(isLoading)return (<></>)
    return (
    <div>
        <FormGroup>
            <Select value={orderOptions.orderType||""} label="Order Type" onChange={(e)=>{
                store("orderType", e.target.value);
                setOrderOptions((prev) => {return {...prev, "orderType": e.target.value }});
                }} size="small">
                {orderType.map(o=>(
                    <MenuItem key={o} value={o}>{o}</MenuItem>
                ))}
            </Select>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                renderInput={(params) => <TextField {...params} size="small"/>} 
                label={orderOptions.orderType + " Date"}
                value={orderOptions.fulfillDate}
                minDate={miniDate}
                minTime={miniTime}
                maxTime={maxiTime}
                shouldDisableDate={getDisabledDates}
                disableHighlightToday
                onChange={(val)=>{
                    store("fulfillDate", val);
                    setOrderOptions((prev) => {return {...prev, "fulfillDate": val }});
                }}/>
            </LocalizationProvider>
        </FormGroup>
    </div>
  );
}

export default OptionsFormGroup;