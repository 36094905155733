import * as React from "react";
import { 
    Box, Typography, Grid, Button
} from '@mui/material';

export const OrderPopup=({item})=>{
    const camelize=(text)=>{
        const textArr = [];
        const arr = text?.toLowerCase().split(" ")||[];
        arr.forEach(a => {
            textArr.push(a.substring(0, 1).toUpperCase() + a.substring(1));
        });
        return textArr.join(" ");
    }

    return(
    <Box sx={{display: 'grid', gridTemplateColumns: { md: 'repeat(3, 1fr)' }, gap: 4, p: 2 }}>
        {item.map((c, i)=>(
        <Grid key={i} container spacing={2} sx={{ boxShadow: 2, p:1, minHeight: 150 }}>
            <Grid item xs>
                <Typography variant="h5" component="div" style={{color: "#003366", fontFamily: "cursive"}}>{camelize(c.title)}</Typography>
                <Typography variant="subtitle2">{new Date(c.date).toLocaleString("en-US", { dateStyle: "full", timeStyle: "short" })}</Typography>
                <Grid item>
                    <Button variant="outlined" color="success" size="small" onClick={()=>window.open("/popup/"+c._id, "_blank")}>Order Now</Button>
                </Grid>
            </Grid>
        </Grid>
        ))}
    </Box>
    )
}