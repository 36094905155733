import * as React from "react";
import { 
    Box, Typography, Button, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, Select,
    RadioGroup, Radio, FormControlLabel, TextField, FormControl, FormGroup, Checkbox, MenuItem, Paper
} from '@mui/material';
import { PlaceOrder } from "../functions/PlaceOrder";
import getCoords from "../functions/Geocode";
import getDistance from "../functions/CalculateDistance";
import SearchAddress from "../functions/SearchAddress";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import CheckoutForm from "./Stripe/CheckoutForm";
import ValidateCustomerInfo from "../functions/ValidateCustomerInfo";
import { PopulateOrderObject } from "../functions/PopulateOrderObject";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const countryCodes=[
    { "country": "us", "code": "1" },
    { "country": "ng", "code": "234" },
]
const defaultPayments ={card:true};
const SharedCheckoutPage=(props)=>{
    const {settings, vendor, cart, orderOption, serviceType, orderTotal, currency} = props;
    const [customerName, setCustomerName] = React.useState("");
    const [customerPhone, setCustomerPhone] = React.useState("");
    const [customerEmail, setCustomerEmail] = React.useState("");    
    const [paymentType, setPaymentType] = React.useState("");
    const [paymentDet, setPaymentDet] = React.useState("");
    const [customerAddress, setCustomerAddress] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [openAddressDialog, setOpenAddressDialog] = React.useState(false);
    const [confirmAddress, setConfirmAddress] = React.useState([]);
    const [subscribeList, setSubscribeList] = React.useState(settings?.subscribeList||[]);
    const [sctCountry, setSctCountry] = React.useState("1");

    const acceptedPayments = (vendor?.settings.filter(a=>a.settingName==="paymentType")[0].settingValue||defaultPayments);

    React.useEffect(() => {
        setPaymentType(Object.keys(acceptedPayments)[0]);
    }, [acceptedPayments]);

    const finishCheckout=async()=>{
        setOpenAddressDialog(false);
        setErrorMessage("");
        const order = {subscribers: subscribeList};
        const orderObject = await PopulateOrderObject(currency, vendor, order, cart, customerName, customerPhone, customerEmail, orderOption, orderTotal, paymentType, customerAddress, serviceType);
        let res = await PlaceOrder(orderObject);

        if(res){
            window.location.href="/order/"+res;
        }
        else{
            setErrorMessage("There was a problem with placing your order. Please try again.");
        }
    }

    const validateCustomerDetails=async()=>{
        let errorMsg = await ValidateCustomerInfo(customerName, customerPhone, customerEmail);
        if(errorMsg!==null){
            setErrorMessage(errorMsg);
            return;
        }
        if(orderOption.orderType === "delivery" && !customerAddress){
            setErrorMessage("Address is required");
            return;
        }
        if(orderOption.orderType === "delivery" && customerAddress){
            const addresses = await SearchAddress(customerAddress);
            if(addresses!=null){
                setConfirmAddress(addresses);
                setOpenAddressDialog(true);
                return;
            }
        }
        await finishCheckout();
    }

    const placeOrder=()=>{
        validateCustomerDetails();
    }

    const handleConfirmAddress=async(addy)=>{
        let customerCoords = {};
        customerCoords.lat = addy.latitude;
        customerCoords.long = addy.longitude;
        
        if(serviceType==="catering"){
            const bizAddy = vendor.businessAddress;
            const catererAddress = `${bizAddy?.street} ${bizAddy?.apt||""} ${bizAddy?.city} ${bizAddy?.state} ${bizAddy?.zip} ${bizAddy?.country}`
            let catererCoords = {};
            if(bizAddy?.coordinates?.latitude && bizAddy?.coordinates?.longitude){
                catererCoords.lat = vendor.businessAddress.coordinates.latitute;
                catererCoords.long = vendor.businessAddress.coordinates.longitude;
            }
            else{
                catererCoords = await getCoords(catererAddress);
            }
            // get max delivery miles. incase caterer doesn't want to travel too far for delivery
            if(customerCoords != null && customerCoords.lat && customerCoords.long && catererCoords != null && catererCoords.lat && catererCoords.long){
                const dist = await getDistance(catererCoords, customerCoords);
                if(dist!==""){
                    dist.replace(" mi", "");
                }
                let maxMiles = 0;
                const genSettings = vendor.settings.filter(s=>s.settingName==="general");
                if(genSettings.length > 0){
                    maxMiles = genSettings[0].settingValue.filter(g=>g.name==="maxMiles")[0]?.value;
                }
                if(parseFloat(dist)>parseFloat(maxMiles)){
                    setErrorMessage(`Sorry, ${vendor.businessName} doesn't deliver this far. Please switch to pickup or use a delivery address closer to the caterer's, thank you. We apologize for the inconvenience.`);
                    return;
                }
            }
        }
        await finishCheckout();
    }

    const handlePaymentChange=(e)=>{
        let pmt = acceptedPayments;
        setPaymentType(e.target.value);
        if(e.target.value!=="cash"){
            setPaymentDet(pmt[e.target.value]);
        }
        else{
            setPaymentDet("");
        }
    }

    const addToSubscribeList=(checked)=>{
        const subscribers = subscribeList;
        if(checked)subscribers.push(customerPhone);
        if(!checked)subscribers.filter(s=>s!==customerPhone);
        setSubscribeList(subscribers);
    }

    const options = {
        mode: "payment",
        amount: +parseInt(orderTotal*100),
        currency: "usd"
    };

    const sctCountryChange=(e)=>{
        setSctCountry(e.target.value);
        setCustomerPhone(e.target.value+customerPhone);
    }

    return(
        <div>
            <Dialog onClose={()=>setOpenAddressDialog(false)} open={openAddressDialog}>
                <DialogTitle textAlign="center" color="success">Confirm Address</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmAddress.map((c,i)=>(
                            <span key={i}>
                                <span>{c.formattedAddress}</span><br />
                                <Button variant="contained" color="error" onClick={() => handleConfirmAddress(c)}>Confirm</Button>
                            </span>
                        ))}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <div>
                <Typography variant="h5" align="left">CHECKOUT</Typography>
                <Divider color="error"/>
                <Divider />
                <Box sx={{
                    backgroundColor: (theme) =>theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}>
                <div>
                    <Box sx={{ width: '100%', boxShadow:2, m:2, p:2, textAlign: "left" }}>
                        <Typography variant="subtitle">Payment</Typography>
                        <Divider color="error"/>
                        <RadioGroup value={paymentType}>
                            {Object.keys(acceptedPayments)
                            .map(p=>(
                                <FormControlLabel key={p} value={p} control={<Radio />} label={p} onChange={handlePaymentChange} />
                            ))}
                        </RadioGroup>
                        {paymentDet&&paymentType!=="card"&&<Typography variant="caption" color="error">* Make the {paymentType} payment to {paymentDet}</Typography>}
                        {paymentType!=="card"?<><br />
                        <Typography variant="subtitle">Billing Information</Typography>
                        <Box>
                            <FormControl fullWidth>
                                <TextField label="Name" size="small" margin="dense" onChange={(e) => setCustomerName(e.target.value)} />
                                <Paper component="form" sx={{ p: "2px 4px", display: "flex" }}>
                                    <FormControl size="small" style={{alignSelf:"center"}}>
                                        <Select value={sctCountry} onChange={sctCountryChange}>
                                            {countryCodes.map((o, i)=>(
                                            <MenuItem key={i} value={o.code}>
                                                <img
                                                    loading="lazy" width="20"
                                                    src={`https://flagcdn.com/w20/${o?.country}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${o?.country}.png 2x`}
                                                    alt={`flag of ${o.country}`}
                                                />&nbsp;{"+" + o.code}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField label="Phone" size="small" sx={{ ml: 1, flex: 1, width: {md: "40vw"}}} margin="dense" inputProps={{ maxLength: 10 }} onChange={(e) => setCustomerPhone(sctCountry + e.target.value)} />
                                </Paper>
                                {/* <TextField label="Phone" size="small" margin="dense" inputProps={{ maxLength: 10 }} onChange={(e) => setCustomerPhone(e.target.value)} /> */}
                                <TextField label="Email" size="small" margin="dense" onChange={(e) => setCustomerEmail(e.target.value)} />
                                {orderOption.orderType === "delivery" && <TextField label="Address" size="small" margin="dense" onChange={(e) => setCustomerAddress(e.target.value)} />}
                                {errorMessage && <span style={{ color: "red", fontSize: 13 }}>* {errorMessage}</span>}
                            </FormControl>
                            <br />
                            <Button variant="outlined" color="secondary" onClick={placeOrder}>Place Order</Button>
                        </Box></>
                        :<Elements stripe={stripePromise} options={options}>
                            <CheckoutForm currency={currency} amount={orderTotal} vendor={vendor} cart={cart} orderOption={orderOption}
                            paymentType={paymentType} serviceType={serviceType}
                            />
                        </Elements>}
                    </Box>
                </div>
            </Box>
            {customerPhone&&serviceType==="popup"&&!settings?.subscribeList?.includes(customerPhone)&&
            <FormGroup>
                <FormControlLabel control={<Checkbox />} onChange={(e)=>addToSubscribeList(e.target.checked)} label={"Check this box to receive alerts about future popups from " + vendor.businessName} />
            </FormGroup>}
            </div>
        </div>
    )
}

export default SharedCheckoutPage;