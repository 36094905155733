const getDistance=async(fromAddress, toAddress)=>{
    let distance = null;
    await fetch(`${process.env.REACT_APP_RADAR_API_URL}route/distance?origin=${fromAddress.lat}, ${fromAddress.long}&destination=${toAddress.lat}, ${toAddress.long}&modes=car&units=imperial`, {
        method: "GET",
        headers: {
          "Authorization": `${process.env.REACT_APP_RADAR_API_KEY}`,
        }
    })
    .then(response=>response.json())
    .then(data=>{
        distance = data.routes.car.distance.text;
    })
    .catch(error=>{
        console.log(error, "Error getting distance");
        distance = null;
    });
    return distance;
}

export default getDistance;